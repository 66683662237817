<template>
  <div>
    <v-menu :offset-y="offset" :close-on-content-click="false" transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </template>

      <v-list>
          <v-sheet class="ma-5">

            <span>Display Filter</span>
        <v-radio-group @change="changed" label="Unit" v-model="displayOptions.unit" row>
          <v-radio label="Value" value="value"></v-radio>
          <v-radio label="Quantity" value="quantity"></v-radio>
          <!-- <v-radio label="Value & Quantity" value="value-quantity"></v-radio> -->
          <!-- <v-radio label="Index" value="index"></v-radio> -->
        </v-radio-group>

        <v-switch
            @change="changed"
            v-model="displayOptions.showProductGroup"
            :label="
            `Show product grouping: ${
                displayOptions.showProductGroup ? 'Yes' : 'No'
            }`
            "
        ></v-switch>


        <v-radio-group
          @change="changed"
          label="Filter Products"
          v-model="displayOptions.withDeltaOnly"
          row
        >
          <v-radio label="All products" :value="false"></v-radio>
          <v-radio label="Products with changes" :value="true"></v-radio>
        </v-radio-group>

        <v-switch
            @change="changed"
            v-model="displayOptions.showEvents"
            :label="
            `Show inventory events: ${
                displayOptions.showEvents ? 'Yes' : 'No'
            }`
            "
        ></v-switch>
        <v-switch
            @change="changed"
            v-model="displayOptions.showProductsWithEvents"
            :label="
            `Show products with inventory events only: ${
                displayOptions.showProductsWithEvents ? 'Yes' : 'No'
            }`
            "
        ></v-switch>
          </v-sheet>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    offset: true,
    displayOptions: {}
  }),
  mounted() {
    this.displayOptions = this.value;
  },
  methods: {
    changed(){
      this.$emit('input', this.displayOptions);
    }
  }
};
</script>